const Agreement = () => {
  return (
    <div className="mt-[20px] content">
      <h1>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ <br className="sm:hidden"/> (Правила использования игры)</h1>
      <p><strong>Общие положения:</strong></p>
      <p>Оферта – публичное предложение Администрации, адресованное любому лицу, заключить с ним Пользовательское Соглашение (далее — Правила игры) на существующих условиях, содержащихся в Правилах игры.</p>
      <p>Акцепт – полное и безоговорочное принятие игроком условий Правил Игры.</p>
      <p>Акцептом настоящих Правил Игры, является совершение Игроком регистрации в приложении Игры на IOS или Android, путем установки и регистрации в соответствующих приложениях, размещенных Администрацией в магазинах приложений AppStore и GooglePlay, а также дальнейшими входами и запуском Игры на своих устройствах.</p>
      <p>Администрация вправе по своему усмотрению и без согласования с Игроками вносить изменения в Игру и Правила Игры.</p>
      <p>Администрация вправе проводить профилактические работы и приостанавливать работу Игры, для проведения профилактических работ. При наличии возможности Администрация Игры проводит профилактические работы в ночное время.</p>
      <p>Техническая поддержка Игроков осуществляется в рабочие дни с 10.00 до 18.00 по Московскому времени через форму обратной связи на сайте игры по адресу: https://www.ikk-games.com/contact</p>
      <p><strong>1. В Игре, общих игровых и иных каналах запрещены следующие действия:</strong></p>
      <p>1.1. Оскорбления пользователей или администрации проекта.</p>
      <p>1.2. Размещение информации, направленной на отвлечение Пользователей от темы беседы и Игры (флуд).</p>
      <p>1.3. Попрошайничество в любой форме, нацеленное на отдельных либо всех Пользователей.</p>
      <p>1.4. Использование Аккаунта другого Пользователя, продажа, передача, покупка Аккаунта, в том числе путём обмена или получения в дар.</p>
      <p>1.5. Запрещается размещение информации, включая информацию в чате, содержащей намерение передать, продать или приобрести Аккаунт или внутриигровую валюту другому Пользователю.</p>
      <p>1.6. Использование оскорблений, сеющих рознь по расовому, национальному, религиозному либо половому признаку.</p>
      <p>1.7. Распространение личной информации третьих лиц, в том числе домашних адресов, номеров телефонов, паспортных данных, без их согласия.</p>
      <p>1.8. Размещение ложной информации об Игре, Администрации, модераторах, Пользователях и иных лицах.</p>
      <p>1.9. Выманивание или вымогательство у Пользователей личной информации.</p>
      <p>Запрещено размещение информации любого характера с целью получения доступа к Аккаунтам и личным данным Пользователей, осуществления финансовых афер и электронного мошенничества. К подобной информации, помимо прочего, относится:</p>
      <ul>
        <li>размещение ссылок на сторонние ресурсы, замаскированные под официальные ресурсы компании;</li>
        <li>рассылка Пользователям каких-либо личных сообщений от имени Администрации и модераторов, в том числе с предложением передать данные или установить программное обеспечение;</li>
        <li>выдача себя за представителей Администрации или модераторов.</li>
      </ul>
      <p>1.10. Размещение в Игре материалов порнографического и эротического характера или ссылок на них.</p>
      <p>1.11. Угрозы в отношении Пользователей, Администрации, Модераторов либо иных лиц.</p>
      <p>1.12. Размещение рекламных сообщений любого рода.</p>
      <p>1.13. Обсуждение вопросов современной политики, как заведомо провоцирующих конфликты.</p>
      <p>1.14. Провоцирование Пользователей на нарушение Правил игры, Пользовательского соглашения и иных правил.</p>
      <p>1.15. Неспортивное поведение в какой-либо форме.</p>
      <p>1.16. Использование в прямой или косвенной форме следующих никнеймов и иных наименований в Игре:</p>
      <p>1.16.1. сеющих рознь по расовому, национальному, религиозному, политическому, либо половому признаку;</p>
      <p>1.16.2. содержащих в себе нецензурные, грубые, бранные или оскорбительные выражения или аббревиатуры;</p>
      <p>1.16.3. содержащих рекламные слоганы, пропаганду спиртных и наркотических веществ.</p>
      <p>1.17. Применение или распространение информации о способах причинения ущерба Игре, Администрации, Пользователям либо иным лицам (бот-программы, автокликеры, запрещённые моды к Игре, баги, информация об и т. п.).</p>
      <p>1.18. Получение игровых элементов, игровых достижений, а также внутриигровой валюты и иных Дополнительных возможностей Игры с нарушением Правил игры.</p>
      <p>1.19. Распространение любой информации об Игре и планах по их развитию, которая является конфиденциальной. К такой информации относится любая информация, не опубликованная в качестве официальных новостей.</p>
      <p>1.20. Использовать Игру способами, не предусмотренными Правилами игры, иными правилами компании, а также выходящими за рамки обычного игрового процесса.</p>
      <p>1.21. Действия или размещение информации, которые по мнению Администрации нарушают законодательство, нормы морали и этики или являются нежелательными.</p>
      <p>1.22. Внутри игры запрещено обсуждение игрового баланса или игровой логики в целом в негативном ключе. Тут действует правило «не нравится — не играй» или «есть предложение — пиши в поддержку». Игровая же среда предназначена для игры.</p>
      <p><strong>2. Встроенные покупки (покупки за реальную валюту)</strong></p>
      <p>2.1. Все встроенные покупки, независимо от метдода оплаты, считаются добровольным пожертвованием в проект.</p>
      <p>2.2. Никакие претензии, связанные с эффектом на игровой процесс от получаемых преимуществ в следствии встроенных покупок не принимаются.</p>
      <p>2.3. Деньги, которые были потрачены на встроенные покупки, не возвращаются.</p>
      <p>3. Игра предоставляется пользователю в соответствии с общепринятым в мировой практике обычаем делового оборота – принципом «АS IS» («таким, каков он есть»). Администрация не гарантирует, что игра не содержит ошибок программирования.</p>
      <p>4. Приложение Football Wars разработано для работы на смартфонах с предустановленными магазинами приложений AppStore и GooglePlay. Администрация не гарантирует работу функционала на эмуляторах, устаревших устройствах и в случаях установки приложения не через официальные магазины GooglePlay и AppStore.</p>
      <p>5. В случае возникновения обстоятельств непреодолимой силы, которые влияют на работоспособность игры или делают ее существование невозможным, администрация игры никакой ответственности не несет.</p>
      <p>6. В случае нарушения правил игры администрация оставляет за собой право заблокировать игровой аккаунт пользователя.</p>
    </div>
  )
}

export default Agreement
