import silverContract from '../assets/images/silver-contract.png'
import goldContract from '../assets/images/gold-contract.png'
import redContract from '../assets/images/red-contract.png'
import redCard from '../assets/images/red-card.png'

import silverEquipment from '../assets/images/silver-equipment.png'
import goldEquipment from '../assets/images/gold-equipment.png'
import redEquipment from '../assets/images/red-equipment.png'

import silverPlayer from '../assets/images/plr_platinum.png'
import goldPlayer from '../assets/images/plr_yellow.png'
import redPlayer from '../assets/images/plr_violet.png'
import greenPlayer from '../assets/images/plr_green.png'
import bluePlayer from '../assets/images/plr_blue.png'

import gold from '../assets/images/gold.png'
import money from '../assets/images/money.png'
import goldBig from '../assets/images/gold_big.png'
import moneyBig from '../assets/images/money_big.png'
import lcur from '../assets/images/lc.png'

import carU from '../assets/images/cu.png'
import carC from '../assets/images/cc.png'
import car1 from '../assets/images/c1.png'
import car2 from '../assets/images/c2.png'
import car3 from '../assets/images/c3.png'
import car4 from '../assets/images/c4.png'
import car5 from '../assets/images/c5.png'
import cityT from '../assets/images/cityT.png'
import seasStud from '../assets/images/seasStud.png'
import seasCur from '../assets/images/seasCur.png'

const imagesContract = [null, silverContract, goldContract, redContract]
const imagesEquipment = [null, silverEquipment, goldEquipment, redEquipment]
const imagesPlayer = [greenPlayer, bluePlayer, goldPlayer, redPlayer, silverPlayer]

export const getType = (product, isBig) => {
  switch (product.lotGroup) {
    case 'Contract':
      return {
        name: 'contract',
        image: getImage(imagesContract, product.rarity),
        styles: 'w-[43px] h-[43px]'
      }
    case 'Equipment':
      return {
        name: 'equipment',
        image: getImage(imagesEquipment, product.rarity),
        styles: 'w-[43px] h-[43px]'
      }
    case 'Card':
      return {
        name: 'card',
        image: redCard,
        styles: 'w-[43px] h-[43px]'
      }
    case 'Gold':
      return {
        name: 'gold',
        image: isBig===true ? goldBig : gold,
        styles: 'w-[17px] h-[17px] bg-center !bg-contain',
        fill: '#FFCA42'
      }
    case 'Money':
      return {
        name: 'money',
        image: isBig===true ? moneyBig : money,
        styles: 'w-[16px] h-[17px]',
        fill: '#57D254'
      }
    case 'LeagueCurrency':
      return {
        name: 'gold',
        image: lcur,
        styles: 'w-[17px] h-[17px] bg-center !bg-contain',
        fill: '#d9d9df'
      }
    case 'Pack':
      return {
        name: 'player',
        image: getImage(imagesPlayer, product.rarity),
        styles: 'w-[27px] h-[34px]'
      }
    default:
      {
        switch (product.lotType) {
          case 24:
            return {
              name: 'city',
              image: cityT,
              styles: 'w-[17px] h-[17px] bg-center !bg-contain',
              fill: '#fbeecd'
            }
            case 40:
              return {
                name: 'careerUni',
                image: carU,
                styles: 'w-[17px] h-[17px] bg-center !bg-contain',
                fill: '#fafafb'
              }
            case 41:
              return {
                name: 'careerCur',
                image: carC,
                styles: 'w-[17px] h-[17px] bg-center !bg-contain',
                fill: '#6a4ce6'
              }
            case 91:
              return {
                name: 'player',
                image: getImage(imagesPlayer, product.rarity),
                styles: 'w-[27px] h-[34px]'
              }
            case 92:
              return {
                name: 'city',
                image: seasStud,
                styles: 'w-[17px] h-[17px] bg-center !bg-contain',
                fill: '#fcca00'
              }
            case 93:
              return {
                name: 'city',
                image: seasCur,
                styles: 'w-[17px] h-[17px] bg-center !bg-contain',
                fill: '#1a89c8'
              }
          default:
            return {}
        }
        }
  }
}

export const getPlayerType = (rarity) => {
  switch (rarity) {
    case 2:
      return '60+'
    case 4:
      return '70+'
    case 8:
      return '80+'
    default:
      return '-'
  }
}

export const getColorName = (rarity) => {
  switch (rarity) {
    case 2:
      return '#1EDBA0'
    case 4:
      return '#2B66FC'
    case 8:
      return '#FFED56'
    case 32:
      return '#E8E8ED'
    case 16:
      return '#9949FF'
    default:
      return '#fff'
  }
}

export const getValue = (value) => {
  return Number.isInteger(value)
    ? new Intl.NumberFormat('ru-RU').format(value)
    : value
}

const getImage = (images, rarity) => {
  switch (rarity) {
    case 2:
      return images[0]
    case 4:
      return images[1]
    case 8:
      return images[2]
    case 16:
      return images[3]
    case 32:
      return images[4]
    default:
      return null
  }
}

export const isGold = name => name === 'gold'
export const isMoney = name => name === 'money'
export const isCoins = name => name === 'money' || name === 'gold' || name === 'city' || name === 'careerUni' || name === 'careerCur'

export const isCity = name => name === 'city' || name === 'careerUni' || name === 'careerCur'

export const isPlayer = name => name === 'player'
