import { useState, useEffect } from 'react'

import Timer from './Timer'
import Exclusive from './Exclusive'

const Category = ({ data, heading, openModal }) => {
  const [products, setProducts] = useState([])
  const [timeIsOver, setTimeIsOver] = useState(false)

  useEffect(() => {
    setProducts(data.marketLots || [])
  }, [data])

  if (products.length > 0 && !timeIsOver) {
    return (
      <div>
        <div className="flex flex-col mb-[9px] py-[9px] px-[8px] justify-center space-x-[10px] xxs:flex-row xxs:items-center bg-gradient-to-r from-[#FC5A0000] via-[#FC5A00] to-[#FC5A0000]">
          <p className="leading-[24px] text-[17px] font-bold text-white">{heading}</p>
          <Timer
            date={new Date(data.promotionTimeEnd)}
            className="mt-[10px] xxs:mt-0"
            timeIsOver={() => setTimeIsOver(true)}
          />
        </div>
        <div className="space-y-[10px]">
          {products.map((product, index) =>
            product.lotType &&
              <Exclusive
                key={index}
                product={product}
                openModal={openModal}
              />
          )}
        </div>
      </div>
    )
  }
}

export default Category
