import { Link } from 'react-router-dom'

const Thankyou = () => {
  return (
    <div className="mt-[30px] space-y-[20px]">
      <p className="text-center font-oswald text-[32px] text-white">Вы снова на сайте:)</p>
      <p className="text-center text-white">Спасибо, вы можете вернуться в магазин</p>
      <div className="text-center">
        <Link
          to="/"
          className="btn w-[160px] !border !border-[#FC5A00] rounded-[10px] !bg-[#FC5A00]"
        >
          В магазин
        </Link>
      </div>
    </div>
  )
}

export default Thankyou
