import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="mt-[30px] space-y-[20px]">
      <p className="leading-[96px] text-center font-oswald text-[96px] text-white">404</p>
      <p className="text-center text-white">Страница не найдена или не была создана</p>
      <div className="text-center">
        <Link
          to="/"
          className="btn w-[160px]"
        >
          На главную
        </Link>
      </div>
    </div>
  )
}

export default NotFound
