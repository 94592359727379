import { useState, useMemo } from 'react'
import axios from 'axios'

import { getValue } from '../../helpers/shop'
import ToggleGroup, {toggles} from '../../components/elements/ToggleGroup'

const SelectRole = ({ product, closeModal }) => {
  const [loading, setLoading] = useState(false)
  const [roleSelected, setRole] = useState(null)

  const team = useMemo(() => JSON.parse(sessionStorage.getItem('team')), [])

  const buy = async () => {
    if (team) {
      setLoading(true)

      try {
        const response = await axios.post('/Market/YookassaPurchase', {
          userId: team.id,
          marketplaceId: product.marketplaceId,
          selectedRole: roleSelected
        })

        window.location.href = response.data
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }
  }

  if (product) {
    return (
      <div className="p-[20px] rounded-[10px] bg-[rgba(0,0,0,0.9)] border border-[rgba(255,255,255,0.4)]">
        <p className="text-center text-[20px] font-semibold text-white">Выберите амплуа платины перед покупкой:</p>
        {/* <p className="mt-[10px] text-center text-white">Вы действительно хотите купить "{product.name}", за {getValue(product.price)}<span className="text-[10px]">₽</span>?</p> */}
        <ToggleGroup func={(v) => setRole(parseInt(v))} />
        <div className="flex mt-[20px] justify-between">
          <button
            type="button"
            className="w-[96px] py-[12px] px-[10px] border border-[#FC5A00] rounded-[10px] leading-[20px] text-white"
            onClick={closeModal}
          >
            Отмена
          </button>
          <button
            type="button"
            className="w-[96px] py-[12px] px-[10px] rounded-[10px] leading-[20px] text-center text-white bg-[#FC5A00] disabled:opacity-50 disabled:cursor-default"
            disabled={!team || roleSelected==null}
            onClick={buy}
          >
            Купить
          </button>
        </div>
      </div>
    )
  }
}

export default SelectRole
