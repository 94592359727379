import { useState, useRef } from 'react'

import { CSSTransition } from 'react-transition-group'

import ReCAPTCHA from 'react-google-recaptcha'
import axios from 'axios'

import Modal from '../components/elements/Modal'
import Success from '../components/modals/Success'

const Support = () => {
  const nameField = useRef()
  const idField = useRef()
  const emailField = useRef()
  const messageField = useRef()

  const recaptchaRef = useRef()

  const [token, setToken] = useState(null)
  const [captchaError, setCaptchaError] = useState(false)

  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false)

  const submit = async () => {
    const fields = [nameField, idField, emailField, messageField]

    let isError = false

    setLoading(true)

    fields.forEach((field) => {
      field.current.value = field.current.value.trim()

      if (!field.current.value) {
        field.current.classList.add('error')

        isError = true
      }
    })

    if (isError || !token) {
      isError = false
      setLoading(false)

      if (!token) {
        setCaptchaError(true)
      } else {
        setToken(null)

        recaptchaRef.current.reset()
      }

      return
    }

    try {
      await axios.post('/Market/RequestMailSupportForWeb', {
        userId: '',
        token: token,
        name: getValue(nameField),
        phone: getValue(idField),
        email: getValue(emailField),
        message: getValue(messageField)
      })

      fields.forEach((field) => {
        field.current.value = ''
        field.current.classList.remove('error')
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
      setVisible(true)

      setToken(null)
      setCaptchaError(false)

      recaptchaRef.current.reset()
    }
  }

  const checkCaptcha = (token) => {
    if (token) {
      setToken(token)
      setCaptchaError(false)
    }
  }

  const getValue = (field) => {
    return field.current.value
  }

  const resetField = (event) => {
    event.target.classList.remove('error')
  }

  return (
    <div className="mt-[20px]">
      <div className="flex flex-wrap mx-[-15px] space-y-[10px] sm:space-y-0">
        <div className="w-full px-[15px] sm:w-4/12">
          <input
            ref={nameField}
            type="text"
            className="input !border !border-[#FC5A00] rounded-[10px]"
            spellCheck="false"
            autoComplete="off"
            placeholder="Ваше имя"
            onFocus={resetField}
          />
        </div>
        <div className="w-full px-[15px] sm:w-4/12">
          <input
            ref={idField}
            type="text"
            className="input !border !border-[#FC5A00] rounded-[10px]"
            spellCheck="false"
            autoComplete="off"
            placeholder="ID команды"
            onFocus={resetField}
          />
        </div>
        <div className="w-full px-[15px] sm:w-4/12">
          <input
            ref={emailField}
            type="text"
            className="input !border !border-[#FC5A00] rounded-[10px]"
            spellCheck="false"
            autoComplete="off"
            placeholder="E-mail"
            onFocus={resetField}
          />
        </div>
        <div className="w-full px-[15px] sm:!mt-[10px]">
          <textarea
            ref={messageField}
            className="textarea h-[146px] !border !border-[#FC5A00] rounded-[10px]"
            autoComplete="off"
            placeholder="Ваше сообщение"
            onFocus={resetField}
          />
        </div>
        <div className="w-full px-[15px] sm:!mt-[10px]">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_SITE_KEY}
            className="origin-top-left scale-95"
            theme="dark"
            onChange={checkCaptcha}
          />
          { captchaError && (
            <p className="mt-[5px] text-[#ff0000]">Докажите, что вы не робот</p>
          )}
        </div>
        <div className="w-full px-[15px] sm:!mt-[10px]">
          <button
            type="button"
            className="btn !border !border-[#FC5A00] rounded-[10px] !bg-[#FC5A00]"
            disabled={loading}
            onClick={submit}
          >
            Отправить сообщение
          </button>
        </div>
      </div>
      <CSSTransition
        in={visible}
        classNames="fade"
        timeout={150}
        unmountOnExit
      >
        <Modal
          isVisible={visible}
          className="max-w-[350px]"
          closeModal={() => setVisible(false)}
        >
          <Success closeModal={() => setVisible(false)}/>
        </Modal>
      </CSSTransition>
    </div>
  )
}

export default Support
