const Success = ({ closeModal }) => {
  return (
    <div className="p-[20px] rounded-[10px] bg-[rgba(0,0,0,0.9)] border border-[rgba(255,255,255,0.4)]">
      <p className="text-center text-[20px] font-semibold text-white">Письмо успешно отправлено!</p>
      <p className="mt-[10px] text-center text-white">Мы свяжемся с Вами в ближайшее время.</p>
      <div className="flex mt-[20px] justify-center">
        <button
          type="button"
          className="w-[96px] py-[12px] px-[10px] border border-[#FC5A00] rounded-[10px] leading-[20px] text-white"
          onClick={closeModal}
        >
          Закрыть
        </button>
      </div>
    </div>
  )
}

export default Success
