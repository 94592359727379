import { NavLink } from 'react-router-dom'

const Navigation = ({ className }) => {
  const links = [
    {
      name: 'Соглашение',
      path: '/agreement'
    },
    {
      name: 'Конфиденциальность',
      path: '/confidentiality'
    },
    {
      name: 'Поддержка',
      path: '/support'
    },
    {
      name: 'Магазин',
      path: '/shop'
    }
  ]

  return (
    <ul className={`flex flex-col py-[15px] space-y-[30px] border-y border-white justify-between font-oswald sm:flex-row sm:space-y-0 sm:border-t-0 ${className || ''}`}>
      {links.map((link, index) =>
        <li
          key={index}
          className="text-center"
        >
          <NavLink
            to={link.path}
            className={({ isActive }) => {
              return `border-b border-transparent leading-[24px] uppercase text-[17px] ${isActive ? 'text-[#FFCA42] !border-transparent' : 'text-white'} transition-[border] duration-150 hover:border-white`
            }}
          >
            {link.name}
          </NavLink>
        </li>
      )}
    </ul>
  )
}

export default Navigation
