import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router } from 'react-router-dom'

import axios from 'axios'

import './assets/styles/index.css'

import App from './App'

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Router>
    <App/>
  </Router>
)
