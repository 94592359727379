import { useState, useRef, useEffect, useCallback } from 'react'
import intervalToDuration from 'date-fns/intervalToDuration'
import icon from '../../../assets/images/timer.png'

const Timer = ({ date, className, timeIsOver }) => {
  const [timer, setTimer] = useState('')

  const intervalRef = useRef(null)

  const format = (start, end) => {
    const duration = intervalToDuration({
      start: start,
      end: end
    })

    const zero = (number) => String(number).padStart(2, 0)

    return `${zero((duration.days * 24) + duration.hours)}:${zero(duration.minutes)}:${zero(duration.seconds)}`
  }

  const setTime = useCallback(() => {
    const now = new Date()
    const userTimezoneOffset = date.getTimezoneOffset() * 60000

    const newDate = new Date(date.getTime() - userTimezoneOffset)

    if (newDate < now) {
      timeIsOver()

      clearTimeout(intervalRef.current)

      return
    }

    setTimer(format(now, newDate))
  }, [date, timeIsOver])

  useEffect(() => {
    if (!timer) {
      setTime()
    }

    intervalRef.current = setTimeout(() => {
      setTime()
    }, 1000)

    return () => clearTimeout(intervalRef.current)
  }, [date, timer, setTime])

  return (
    <p className={`flex leading-[24px] text-[17px] text-white ${className || ''}`}>
      <i
        className="shrink-0 w-[24px] h-[25px] bg-cover mr-[7px]"
        style={{ backgroundImage: `url(${icon})` }}
      />
      {timer}
    </p>
  )
}

export default Timer
