const Confidentiality = () => {
  return (
    <div className="mt-[20px] content">
      <h1>ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</h1>
      <p>1.2 Настоящая Политика Конфиденциальности регулирует режим обработки персональных данных, которые мы собираем, когда вы получаете доступ к нашей игре, но не действия компаний или лиц, которые мы не контролируем.</p>
      <p>1.3 Наше приложение не собирает данные, относящиеся к личности пользователя. Но, чтобы иметь возможность улучшать нашу игру, мы собираем некоторые статистические данные об её игроках:</p>
      <ol>
        <li>Тип устройства.</li>
        <li>Идентификационный номер устройства.</li>
        <li>Источник загрузки приложения.</li>
        <li>Страна и город пребывания пользователя.</li>
        <li>Продолжительность игровой сессии.</li>
        <li>Сообщения о возможных неполадках.</li>
      </ol>
      <p>1.4 Покупка платного контента в игре защищена от случайного приобретения и регулируется правилами компаний Apple для приложений iOS и Google для приложений Android. Кроме того, хотим отметить, что использования платных функций является абсолютно добровольной и необязательной для полноценного участия в игровом процессе и решение об использовании этих функций всегда остается за пользователем.</p>
      <p>1.5 Для сохранения игрового прогресса мы используем привязку к электронной почте. Адрес электронной почты используется исключительно для подтверждения привязки. Предоставление адреса электронной почты является абсолютно добровольным.</p>
      <p>1.6 Мы можем пересматривать данную политику конфиденциальности время от времени. Режим обработки персональных данных функционирует в соответствии с действующей редакцией данной политики.</p>
    </div>
  )
}

export default Confidentiality
