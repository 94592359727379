import React, { Component } from "react";

class ToggleGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "React"
    };
    this.onChangeValue = this.onChangeValue.bind(this);
  }

  onChangeValue(event) {
    this.props.func(event.target.value);
  }

  render() {
    return (
    <div>
        <div onChange={this.onChangeValue} className="text-white">
            <label><input type="radio" value="1" name="role" /> <span>Главный тренер (Гл. ТРН)</span></label><br/>
            <label><input type="radio" value="2" name="role" /> <span>Тренер вратарей (ТРН Вр.)</span></label><br/>
            <label><input type="radio" value="4" name="role" /> <span>Ассистент тренера (Асс. ТРН)</span></label><br/>
            <label><input type="radio" value="8" name="role" /> <span>Доктор (ДОК)</span></label><br/>
            <label><br/></label>
            <label><input type="radio" value="32768" name="role" /> <span>Форвард (ФВД)</span></label><br/>
            <label><input type="radio" value="16384" name="role" /> <span>Левый вингер (ЛВ)</span></label><br/>
            <label><input type="radio" value="8192" name="role" /> <span>Правый вингер (ПВ)</span></label><br/>
            <label><input type="radio" value="4096" name="role" /> <span>Центральный опорный полузащитник (ЦОП)</span></label><br/>
            <label><input type="radio" value="2048" name="role" /> <span>Центральный атакующий (ЦАП)</span></label><br/>

            <label><input type="radio" value="1024" name="role" /> <span>Левый полузащитник (ЛП)</span></label><br/>
            <label><input type="radio" value="512" name="role" /> <span>Правый полузащитник (ПП)</span></label><br/>
            <label><input type="radio" value="256" name="role" /> <span>Центральный полузащитник (ЦП)</span></label><br/>
            
            <label><input type="radio" value="128" name="role" /> <span>Левый защитник (ЛЗ)</span></label><br/>
            <label><input type="radio" value="64" name="role" /> <span>Правый защитник (ПЗ)</span></label><br/>
            <label><input type="radio" value="32" name="role" /> <span>Центральный защитник (ЦЗ)</span></label><br/>
            
            <label><input type="radio" value="16" name="role" /> <span>Вратарь (ВРТ)</span></label><br/>
        </div>
    </div>
    );
  }
}

export default ToggleGroup;