import { useEffect, useState, useRef } from 'react'
import axios from 'axios'

import { useSearchParams } from 'react-router-dom'

const Home = ({ setLoggedIn }) => {
  const [searchParams] = useSearchParams()
  const userId = searchParams.get('id')

  const [value, setValue] = useState('')
  const [loading, setLoading] = useState(false)

  const idField = useRef()

  useEffect(() => {
    if (userId) {
      getUser()
    }
  })

  const getUser = async () => {
    try {
      await fetchUser(userId)

      setLoggedIn(true)
    } catch (error) {
      console.error(error)
    }
  }

  const submit = async () => {
    if (!value.trim()) {
      idField.current.classList.add('error')

      return
    }

    setLoading(true)

    try {
      await fetchUser(value)

      setValue('')
      setLoggedIn(true)
    } catch (error) {
      if (error.response.status === 404) {
        idField.current.classList.add('error')
      }

      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const fetchUser = async (userId) => {
    const response = await axios.get(`/Market/GetTeamInfoForWeb/${userId}`)

    sessionStorage.setItem('team', JSON.stringify({
      id: userId,
      name: response.data.name
    }))
  }

  return (
    <div className="mt-[30px]">
      <p className="max-w-[465px] mx-auto text-center font-oswald text-white">Для авторизации в разделе используйте ID команды, который можно скопировать в настройках игры. Для перехода в настройки нажмите на логотип своей команды.</p>
      <div className="w-[160px] mt-[30px] mx-auto space-y-[10px]">
        <div>
          <input
            ref={idField}
            type="text"
            className="input text-center"
            value={value}
            spellCheck="false"
            autoComplete="off"
            placeholder="ID команды"
            onChange={event => setValue(event.target.value)}
            onFocus={event => event.target.classList.remove('error')}
          />
        </div>
        <div>
          <button
            type="button"
            className="w-[60px] p-[10px] leading-[18px] font-oswald text-white transition-[background] duration-[150] !border !border-[#FC5A00] rounded-[10px] !bg-[#FC5A00] hover:bg-[#3a3a3a] active:bg-[#5e5b5b] disabled:opacity-50 disabled:cursor-default"
            disabled={loading}
            onClick={submit}
          >
            Войти
          </button>
        </div>
      </div>
    </div>
  )
}

export default Home
