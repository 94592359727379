import {
  isGold,
  isMoney,
  isPlayer,
  getType,
  getColorName,
  getValue
} from '../../../helpers/shop'

import vipPoints from '../../../assets/images/vip-points.png'

const RushItem = ({ product, openModal }) => {
  const { name, image, styles, fill } = getType(product)

  const count = product.purchaseLimit - product.userPurchasesCount
  const limit = count < 0 ? 0 : count

  const getPlayerName = () => {
    if (isPlayer(name)) {
      const array = product.name.split(' ')

      switch (product.id) {
        case 36:
        case 37:
          array[1] = `<span style="color: ${getColorName(product.rarity)}">${array[1]}</span>`
          break;
        case 38:
        case 39:
        case 40:
          array[0] = `<span style="color: ${getColorName(product.rarity)}">${array[0]}</span>`
          break;
        case 35:
          array[1] = `<span style="color: ${getColorName(product.rarity)}">${array[1]}</span>`
          break;

        default:
          array[2] = `<span style="color: ${getColorName(product.rarity)}">${array[2]}</span>`
          break;
      }

      return { __html: array.join(' ') }
    }
  }

  const buy = () => {
    if (limit === 0) {
      return
    }

    openModal(product)
  }

  return (
    <div className="flex flex-col justify-between rounded-[10px] bg-[#363333] xxs:flex-row sm:min-h-[56px]">
      <div className="w-full pt-[1px] px-[1px]">
        <div className={`flex flex-col h-full py-[10px] pr-[15px] ${!isPlayer(name) && !isGold(name) && !isMoney(name) ? 'pl-[7px]' : 'pl-[15px]'} justify-between sm:flex-row sm:py-[2px] sm:items-center`}>
          {isPlayer(name)
            ? (
              <div className="flex items-center">
                {image && (
                  <i
                    className={`shrink-0 ${styles} bg-cover`}
                    style={{ backgroundImage: `url(${image})` }}
                  />
                )}
                <div className="ml-[22px]">
                  <p
                    className="leading-[18px] text-[17px] font-bold text-white"
                    dangerouslySetInnerHTML={getPlayerName()}
                  />
                  <div className="flex mt-[2px] space-x-[5px]">
                    <i
                      className="shrink-0 w-[16px] h-[16px] bg-cover"
                      style={{ backgroundImage: `url(${vipPoints})` }}
                    />
                    <p className="leading-[14px] text-[10px] text-white">+{getValue(product.vipPoints)} VIP EXP</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex space-x-[7px] items-center">
                {(isGold(name) || isMoney(name)) && (
                  <svg
                    width="11"
                    height="12"
                    className="shrink-0"
                    viewBox="0 0 11 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.373 4.97461V6.67383H0.626953V4.97461H10.373ZM6.4082 0.824219V11.1758H4.60156V0.824219H6.4082Z"
                      fill={fill}
                    />
                  </svg>
                )}
                {image && (
                  <i
                    className={`shrink-0 ${styles} bg-no-repeat bg-cover`}
                    style={{
                      width: isGold(name) && '24px',
                      backgroundImage: `url(${image})`
                    }}
                  />
                )}
                <div className={(!isGold(name) && !isMoney(name)) ? '!ml-[14px]' : ''}>
                  <p className="text-[16px] font-bold text-white">{isGold(name) || isMoney(name) ? getValue(product.quantity) : product.name}</p>
                  <div className="flex mt-[2px] space-x-[5px]">
                    <i
                      className="shrink-0 w-[16px] h-[16px] bg-cover"
                      style={{ backgroundImage: `url(${vipPoints})` }}
                    />
                    <p className="leading-[14px] text-[10px] text-white">+{getValue(product.vipPoints)} VIP EXP</p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
      <button
        className="block w-full p-[18px] leading-[18px] text-[16px] m-[6px] rounded-[10px] text-[#363333] bg-[#FEC92B] xxs:flex xxs:shrink-0 xxs:w-[100px] xxs:p-0 xxs:items-center xxs:justify-center sm:w-[116px] disabled:opacity-50 disabled:cursor-default"
        disabled={limit === 0}
        onClick={buy}
      >
        <span className="block">
          {getValue(product.price)}
          <span className="text-[10px]">₽</span>
        </span>
      </button>
    </div>
  )
}

export default RushItem
