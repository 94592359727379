import { useEffect } from 'react'
import { createPortal } from 'react-dom'

const Modal = ({ children, isVisible, className, closeModal }) => {
  useEffect(() => {
    if (isVisible) {
      document.body.classList.add('overflow-hidden')

      return () => {
        document.body.classList.remove('overflow-hidden')
      }
    }
  }, [isVisible])

  return createPortal(
    <div className="absolute z-[1]">
      <div
        className="fixed flex inset-0 p-[15px] items-center justify-center overflow-y-auto overflow-x-hidden z-[2]"
        onClick={closeModal}
      >
        <div
          className={`w-full my-auto ${className || ''}`}
          onClick={event => event.stopPropagation()}
        >
          {children}
        </div>
      </div>
      <div className="fixed inset-0 bg-[rgba(0,0,0,0.5)] z-[1]"/>
    </div>,
    document.getElementById('modal-root')
  )
}

export default Modal
