import { isMoney, getType, getValue } from '../../../helpers/shop'

import vipPoints from '../../../assets/images/vip-points.png'

const Product = ({ product, openModal }) => {
  const { name, image, styles, fill } = getType(product, true)

  return (
    <div className="relative rounded-[10px] bg-gradient-to-br from-[#363333] to-[#E8880E]">
      <div class='absolute w-[40px] h-[40px] z-10 justify-center'
      style={{
        right: '10px',
        bottom: '20px'
      }}>
        { image && (
              <i
                className={`shrink-0 ${styles} bg-no-repeat bg-cover w-full h-full block`}
                style={{ backgroundImage: `url(${image})` }}
              />) 
        }
      </div>
      <div className="pt-[1px] px-[1px]">
        <div className="flex flex-col h-[55px] pl-[16px] items-start justify-center">
          <div className="flex space-x-[7px] items-start justify-center">
            {/* <svg
              width="11"
              height="12"
              className="shrink-0"
              viewBox="0 0 11 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.373 4.97461V6.67383H0.626953V4.97461H10.373ZM6.4082 0.824219V11.1758H4.60156V0.824219H6.4082Z"
                fill={fill ? fill : '#57D254'}
              />
            </svg> */}
            {/* image && (
              <i
                className={`shrink-0 ${styles} bg-no-repeat bg-cover`}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) */}
            <p className="text-[16px] text-white">{getValue(product.quantity)}</p>
          </div>
          <div className="flex mt-[2px] space-x-[5px] items-center justify-center">
            <i
              className="shrink-0 w-[16px] h-[16px] bg-cover"
              style={{ backgroundImage: `url(${vipPoints})` }}
            />
            <p className="leading-[14px] text-[10px] text-white">+{getValue(product.vipPoints)} VIP EXP</p>
          </div>
        </div>
      </div>
      <button
        type="button"
        className={`block w-full py-[9px] pr-[9px] pl-[16px] leading-[18px] rounded-b-[10px] text-[16px] text-black bg-gradient-to-r from-[#FEC92B] via-[#FEC92B] to-[#FC5A0000] text-start`}
        onClick={() => openModal(product)}
      >
        {getValue(product.price)}
        <span className="text-[10px]">₽</span>
      </button>
    
    </div>
  )
}

export default Product
