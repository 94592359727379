import { useState } from 'react'
import { Routes, Route, Link, Navigate } from 'react-router-dom'

import Navigation from './components/sections/Navigation'

import Home from './pages/Home'
import Agreement from './pages/Agreement'
import Confidentiality from './pages/Confidentiality'
import Support from './pages/Support'
import Shop from './pages/Shop'
import Thankyou from './pages/Thankyou'
import NotFound from './pages/NotFound'


import logotip from './assets/images/logotip.png'

const App = () => {
  const [loggedIn, setLoggedIn] = useState(!!sessionStorage.getItem('team'))

  const team = JSON.parse(sessionStorage.getItem('team'))

  return (
    <div className="pt-[30px] pb-[50px] sm:pb-[90px]">
      <div className="container">
        <div className="flex justify-center">
          <Link to={loggedIn ? '/shop' : '/'}>
            <img
              src={logotip}
              className="w-[160px] mx-auto"
              alt="Football Wars"
            />
          </Link>
        </div>
        <Navigation className="mt-[35px]"/>
        {(team && team.name) && (
          <div className="py-[9px] px-[15px] mt-[10px] bg-[rgba(255,255,255,0.2)]" style={{borderRadius: 10}}>
            <p className="text-[10px] font-semibold text-white">{team.name}</p>
          </div>
        )}
        <Routes>
          <Route
            path="/"
            element={
              !loggedIn
                ? <Home setLoggedIn={(value) => setLoggedIn(value)}/>
                : <Navigate to="/shop" replace/>
            }
          />
          <Route
            path="/agreement"
            element={<Agreement/>}
          />
          <Route
            path="/confidentiality"
            element={<Confidentiality/>}
          />
          <Route
            path="/support"
            element={<Support/>}
          />
          <Route
            path="/shop"
            element={
              loggedIn
                ? <Shop setLoggedIn={(value) => setLoggedIn(value)}/>
                : <Navigate to="/" replace/>
            }
          />
          <Route
            path="/thankyou"
            element={<Thankyou/>}
          />
          <Route
            path="*"
            element={<NotFound/>}
          />
        </Routes>
      </div>
    </div>
  )
}

export default App
